import React, {Component} from 'react';
import {Button, Text, TextInput, View, Linking} from 'react-native';
import 'ws-audio-api'


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            recordingUrl: '',
            status: [],
            callee1: '',
            callee1Name: '',
            callee2: '',
            callee2Name: '',
            countryCode: ''
        };
    }

    componentDidMount() {
        setInterval(() => {
            if (this.state.id && !this.state.recordingUrl) {

                fetch('conferenceStatus', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: {
                            id: this.state.id
                        }
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => response.result ? response.result : {})
                    .then((response) => this.setState({status: response.status ? response.status : []}))

                fetch('conferenceRecording', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data: {
                            id: this.state.id
                        }
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => response.result ? response.result : {})
                    .then((response) => this.setState({recordingUrl: response.recordingUrl ? response.recordingUrl : ''}))


            }
        }, 1000);
    }

    render() {
        if (this.state.id) {
            if (this.state.recordingUrl) {
                return (
                    <Text style={{color: 'blue'}}
                          onPress={() => Linking.openURL(this.state.recordingUrl)}>
                        'Recording is here'
                    </Text>
                );
            } else {
                return (
                    <View style={{flex: 1}}>
                        <Text>Waiting for recording... status: {this.state.status.join()}</Text>
                    </View>
                );
            }
        }
        return (
            <View style={{padding: 10}}>
                <TextInput
                    style={{height: 40}}
                    placeholder='Type first callee'
                    onChangeText={(callee1) => this.setState({callee1})}
                    value={this.state.callee1}
                />
                <TextInput
                    style={{height: 40}}
                    placeholder='Type first callee name'
                    onChangeText={(callee1Name) => this.setState({callee1Name})}
                    value={this.state.callee1Name}
                />
                <TextInput
                    style={{height: 40}}
                    placeholder='Type other callee'
                    onChangeText={(callee2) => this.setState({callee2})}
                    value={this.state.callee2}
                />
                <TextInput
                    style={{height: 40}}
                    placeholder='Type other callee name'
                    onChangeText={(callee2Name) => this.setState({callee2Name})}
                    value={this.state.callee2Name}
                />
                <TextInput
                    style={{height: 40}}
                    placeholder='Type country code (optional)'
                    onChangeText={(countryCode) => this.setState({countryCode})}
                    value={this.state.countryCode}
                />
                <Button
                    onPress={() => {
                        fetch('conference', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(
                                {
                                    data: {
                                        callees: [
                                            {
                                                number: this.state.callee1,
                                                name: this.state.callee1Name
                                            },
                                            {
                                                number: this.state.callee2,
                                                name: this.state.callee2Name
                                            }
                                        ],
                                        countryCode: this.state.countryCode
                                    }
                                }),
                        })
                            .then((response) => response.json())
                            .then((response) => response.result)
                            .then((response) => this.setState({id: response.id}))
                            .catch((error) => {
                                console.error(error);
                            });
                    }}
                    title='Meshuga!'
                />
            </View>
        );
    }
}